/* 
@font-face {
    font-family: Poppins;
    font-weight: 300;
    src: url('../fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
    font-family: Poppins;
    font-weight: 400;
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins;
    font-weight: 500;
    src: url('../fonts/Poppins/Poppins-Medium.ttf');
}
@font-face {
    font-family: Poppins;
    font-weight: 600;
    src: url('../fonts/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: Poppins;
    font-weight: 700;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
}
@font-face {
    font-family: "Fjalla One";
    font-weight: 700;
        src: url('../fonts/FjallaOne/FjallaOne-Regular.ttf');
} */

button {
    font-family: 'Azo Sans', sans-serif;
    font-size: 14px !important;
}


body {
    font-family: 'Azo Sans', sans-serif;
    font-size: 14px !important;
    color: #021626;
    overflow-y: hidden;
    height: 100%;
    font-size: 14px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
}

.text-color {
    color: rgba(50, 57, 128, 1);
}

.align {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}


/*Estilos pertenecientes a login*/
.form-login {
    margin: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #FFFFFF;
}

.content-login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title-login {
    font-size: 33px;
}

.subtitle-login {
    font-size: 18px;
    font-weight: 600;
}

.inputlogin {
    height: 50px;
    width: 429px;
    border-radius: 15px;
}

.inputresetmail {
    width: 590px;
    height: 45px;
    border-radius: 15px;
}

.btn-login {
    height: 46px;
    color: #FFFFFF;
    width: 429px;
    border-radius: 8px;
    background: rgba(212, 17, 28, 1);
}

.btn-login:hover {
    color: #FFFFFF;
    background: rgba(212, 17, 28, 0.7);
}

.container-fix-login {
    margin: 0;
}

.img-login {
    height: 100vh;
    position: relative;
    padding: 0;
}

.is-link.input,
.is-link.textarea {
    border-color: #323980;
}

.align {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modal-card-head {
    border-bottom: 1px solid #dbdbdb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
    background-color: #FFFFFF;
}

.modal-card-foot {
    background-color: #FFFFFF;
    justify-content: center;
}

.btn-enviar {
    width: 181px;
    border-radius: 5px;
    color: #FFFFFF;
    background: #323980;
    border-radius: 5px;
}

.btn-enviar:hover {
    color: #FFFFFF;
    background: rgba(50, 57, 128, 0.7);

}

.btn-disable {
    width: 181px;
    border-radius: 5px;
    color: #0c0b0b;
    background: #8088e1;
    border-radius: 5px;
}

.btn-disable:hover {
    color: #FFFFFF;
    background: rgba(50, 57, 128, 0.7);

}


.info-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-image: url(../images/Background-login.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;

}

@media screen and (max-width: 942px),
print {

    .column.is-half,
    .column.is-half-tablet {
        flex: none;
        width: 0%;
    }
}

/*************************************/

/*sidebar*/
.sidebar {
    width: 280px;
    background: #323980;
    position: fixed;
    height: 100vh;
    transition: .15s ease-in-out;
}

.menu-list a {
    color: #FFFFFF;
    padding-top: 15px;
    width: 217px;
    height: 50px;
    transition: .15s ease-in-out;
    text-align: left;
    padding-left: 30px;
}

.menu-list a:hover {
    background: rgba(97, 110, 255, 0.3);
    color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
}

.menu-list a.is-active {
    color: #fff;
    height: 50px;
    width: 217px;
    background: #41488A;
    border-radius: 10px;
    padding-top: 15px;
}

.menu-list img {
    width: 75%;
}

.menu ul {
    text-align: -webkit-center;
}

.expand i {
    color: #FFFFFF;
}

.menu-list i {
    font-size: 12px;
}

/*********/

/*navbar**/
.main-content {
    left: 280px;
    top: 2rem;
    position: absolute;
    width: calc(100vw - 280px) !important;
}

.navbar {
    left: 280px;
    position: fixed;
    background-color: #FFFFFF;
    top: 0;
    width: calc(100% - 280px);
    border-bottom: 1px solid rgba(85, 82, 217, 0.2);
}

.navbar-link,
a.navbar-item {
    border-bottom: 2px solid rgba(50, 57, 128, 0.57);
}

i.fas.fa-sign-out-alt.mr-5 {
    color: #ED2243;
}

.red-color {
    color: #ED2243;
}

.font-600 {
    font-weight: 600;
}

.font-size {
    font-size: 18px;
}

.content-box {
    /*overflow-y: auto;*/
    height: auto;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    border: 1px solid rgba(85, 82, 217, 0.24);
}

.btn-notificar-todas {
    text-align: end;
    margin-right: 2rem;
    position: relative;
}

.btn-all {
    text-align: center;
    position: absolute;
    right: 45px;
    height: 30px;
    width: 200px;
    border-radius: 5px;
    background: rgba(25, 91, 171, 1);
    color: #FFFFFF;
    bottom: 10px;
}

.table-list {
    width: 98%;
    margin-left: 10px;
}

.table-header {
    width: 154px;
    height: 50px;
    background: #F7F5F5 !important;
    border-radius: 0px;
    border-bottom: 1px solid #E5E7EB;
    border-top: 1px solid #E5E7EB;
}

.input-search {
    width: 400px;
    height: 38px;
}

.date-input {
    width: 180px;
    height: 38px;
    border-radius: 8px !important;
    border: 1px solid rgba(85, 82, 217, 0.24);
}

.react-date-picker__wrapper {
    border: none !important;
}

::placeholder {
    font-family: 'Azo Sans', sans-serif;
    font-size: 14px;
}

.select-options {
    height: 38px !important;
    max-width: 240px;
    border-radius: 8px !important;
    border: 1px solid rgba(85, 82, 217, 0.24) !important;
    /*width: 240px;*/

}

/* select{
    font-size: 13px !important;
} */

.content-buscar {
    width: 83%;
}

.btn-buscar {
    color: #FFFFFF;
    height: 38px;
    width: 148px;
    border-radius: 5px;
    background: rgba(25, 91, 171, 1);
}

.btn-buscar:hover {
    color: #FFFFFF;
    background: rgba(25, 91, 171, 0.7);
}


.item-colum {
    display: grid;
}

.item-select {
    height: 38px;
    width: 159px;
    border-radius: 8px;
}

.btn-other {
    height: 30px;
    width: 110px;
    border-radius: 4px;
    color: rgba(50, 57, 128, 1);
    background: rgba(219, 233, 234, 1);
    font-size: 14px;
}

.pd-un {
    padding: 1rem;
}

.box-reg {
    height: 22px !important;
    width: 176px;
    border-radius: 10px !important;
    background: #DEF7EC;
    border-radius: 10px;
    color: rgba(3, 84, 63, 1);
    padding: 1px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-cuenta-activa {
    height: 22px !important;
    width: 91px;
    background: #DEF7EC;
    border-radius: 10px;
    color: rgba(3, 84, 63, 1);
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-cuenta-bloqueada {
    height: 22px !important;
    width: 91px;
    background: rgba(251, 213, 213, 1);
    border-radius: 10px;
    color: rgba(155, 28, 28, 1);
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-bloq-permanent {
    height: 22px !important;
    border-radius: 10px;
    background: rgba(251, 213, 213, 1);
    color: #9B1C1C;
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-pending {
    background-color: #FFCA5A;
    color: #926200;
    height: 22px !important;
    width: 176px;
    padding: 1px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px !important;
}

.box-bloq-temp {
    height: 22px !important;
    width: 176px;
    border-radius: 10px !important;
    background: rgba(251, 213, 213, 1);
    color: #9B1C1C;
    border-radius: 10px;
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-medio {
    height: 22px !important;
    width: 176px;
    border-radius: 10px !important;
    background: #E1FF8D;
    color: rgba(105, 142, 0, 1);
    border-radius: 10px;
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bloq-login {
    height: 22px !important;
    width: 176px;
    border-radius: 10px !important;
    background: rgba(251, 213, 213, 1);
    color: #9B1C1C;
    border-radius: 10px;
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-inactiva {
    height: 22px !important;
    border-radius: 10px;
    background: rgba(251, 213, 213, 1);
    color: #9B1C1C;
    padding: 1px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bloq-bo {
    height: 22px !important;
    border-radius: 10px;
    background: rgba(251, 213, 213, 1);
    color: #9B1C1C;
    padding: 1px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-avanzado {
    height: 22px !important;
    color: #09471B;
    border-radius: 10px;
    background: #66FF59;
    padding: 1px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bloq_factura {
    height: 22px !important;
    border-radius: 10px !important;
    background-color: #FFCA5A;
    color: #926200;
    border-radius: 10px;
    padding: 1px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tr-size {
    height: 45px;
}

.table td,
.table th {
    border: none;
    vertical-align: middle !important;
    cursor: pointer;
}

.table th {
    color: rgba(107, 114, 128, 1);
    font-weight: 600 !important;
    background-color: #FFFFFF;
}

.pagination,
.pagination-list {
    justify-content: end !important;
}

.pagination-link.is-current {
    background: rgba(50, 57, 128, 1);
}




.breadcrumb {
    font-size: 14px;
}

.link-editar-datos {
    font-size: 11px;
    color: rgba(50, 57, 128, 1);

}

a {
    text-decoration: none;
}

.d-f {
    display: flex;
}

.d-g {
    display: grid;
}

input {
    height: 38px;
    border-radius: 8px !important;
    border: 1px solid rgba(85, 82, 217, 0.24);
}

label {
    font-size: 14px;
    font-weight: 600;
}

.btn-desbloquear {
    height: 38px !important;
    color: #FFFFFF;
    padding: 1rem;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: rgba(237, 34, 67, 1);
}

.btn-desbloquear:hover {
    color: #ffffff;
    background: rgba(237, 34, 67, 0.7);
}

.btn-resetear {
    height: 38px;
    color: #FFFFFF;
    width: 100%;
    border-radius: 4px;
    background: rgba(25, 91, 171, 1);
}

.btn-resetear:hover {
    color: #ffff;
    background: rgba(25, 91, 171, 0.7);
}

.btn-editar {
    width: 100%;
    height: 38px;
    border: 1px solid rgba(25, 91, 171, 1)
}

.btns-position {
    display: flex;
    justify-content: flex-end;
}

button {
    font-size: 14px !important;
}

.btn-guardar-cambios {
    height: 38px;
    width: 162px;
    background: #195BAB;
    border-radius: 5px;
    color: #FFFFFF;
}

.btn-guardar-cambios:hover {
    color: #FFFFFF;
    background: rgba(25, 91, 171, 0.7);

}

.button.btn-guardar-cambios:disabled,
button.btn-cancelar-cambios:disabled {
    background: #F8F8F8;
    border: 1px solid #E1E3E6;
    border-radius: 8px;
    color: rgba(195, 199, 206, 1);
}

.btn-cancelar-cambios {
    height: 38px;
    color: #FFFFFF;
    width: 162px;
    border-radius: 8px;
    background: #ECF1F3;
    border-radius: 8px;
    color: rgba(25, 91, 171, 1);
}

.btn.disabled {
    opacity: .65;
    pointer-events: none;
}

.box-ci {
    height: 163px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DDE1E8;
    border-radius: 8px;
    margin-top: 10px;
}

.box-ci span {
    font-size: 10px;
    padding-left: 15px;
    color: #00BF2A;
}

.p-size {
    padding-left: 15px;
    font-size: 10px;
}

.btn-plus {
    position: absolute;
    height: 29px;
    width: 29px;
    border-radius: 4px;
    background: #FFFFFF;
    color: #4C4C66;
    margin-left: 180px;
    padding: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    margin-top: 10px;
    cursor: pointer;
}

.border-b {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.title-details {
    font-weight: 600;
    font-size: 20px;
}

.modal-card-body i {
    font-size: 48px;
    color: #ED2243;
    padding: none !important;
}

.tabs.is-fullwidth li {
    background: rgba(143, 146, 161, 0.2);
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.04);
    border-top: 8px;
    height: 53px;
    min-width: 173px;
}

.tab .is-active {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.04);
    border-top: 8px;
    height: 53px;
}

.tabs.is-boxed li.is-active a {
    height: 53px;
    /*width: 173px;*/
}


.tabs.is-boxed a {
    height: 53px;
}

.text-concept {
    font-weight: 600;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
    background: #F9FAFB;
    border-radius: 4px;
}

.a-end {
    text-align: end;
}

.section {
    padding: 1rem 1rem !important;
}

.pt-text {
    padding-top: 16px;
}

.btn-estado {
    height: 37px;
    width: 201px;
    /* left: 411px; */
    border-radius: 8px;

}

.button.btn-estado.is-active {
    background: rgba(25, 91, 171, 1);
    color: #FFFFFF;
}

.button.btn-estado {
    background: rgba(25, 91, 171, 0.7);
    color: #FFFFFF;

}

.btn-primary {
    background: rgba(25, 91, 171, 1);
    border-radius: 4px;
    height: 38px;
    width: 174px;
    color: #FFFFFF;
}

.button.btn-primary:hover {
    background: rgba(25, 91, 171, 0.6);
    border-radius: 4px;
    height: 38px;
    width: 174px;
    color: #FFFFFF;
}

.is-hide {
    display: none;
}

.is-show {
    display: block;
}

.small {
    width: 94px;
}

.menu-list i {
    cursor: pointer;
}

.d-n {
    opacity: 0;
}

.menu-list.hov:hover {
    background-color: red;
}

aside.small ul.menu-list li a {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu.sidebar.small .menu-list a {
    padding: 0;
}

aside.small ul.menu-list li a {
    width: 50px;
    height: 50px;
}

aside.small ul.menu-list li a.is-active i {
    display: block;
}

aside ul.menu-list li a span {
    transition: .5s ease-in-out;
    padding-left: 10px;
}

aside.small ul.menu-list li a span {
    display: none;
}

aside.small ul.menu-list li a i {
    display: block;
}

/* .menu-list a.is-active i{
    display: none;
} */

.hide-ele {
    opacity: 0;
}

.nav-full {
    left: 94px;
    width: calc(100% - 94px);
}

.main-content-expand {
    left: 93px !important;
}



/* .container.is-fluid.main-content.main-content-expand section.hero.mt-4.mb-6{
    width: 95%;
} */

.b-l {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.hero {
    border-radius: 16px;
    border: 1px solid rgba(85, 82, 217, 0.24);
}

.style-ul {
    text-align: initial !important;
    padding-top: 0.5rem;
    font-weight: 400;
    padding-left: 4rem;
}

small.comment-text {
    color: #a79b8d;
    font-size: 12px;
}

.navbar-menu {
    transition: .15s ease-in-out;
}

ul.menu-list.mt-4 ul#options-services li a:hover {
    background: none;
    opacity: 0.7;
}

.menu.sidebar .main-logo {
    transition: .15s ease-in-out;
}

.menu.sidebar.small .main-logo {
    display: none;
}

aside.small ul.menu-list.mt-4 ul#options-services li a {
    display: none;
}

.title-table {
    font-weight: 600;
}

.icon-box {
    border-radius: 10px;
    width: 35px;
    height: 35px;
    background: rgba(25, 91, 171, 1);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-box i {
    color: #FFFFFF;
}

/*.table td, .table th{*/
/*    padding: 0;*/
/*}*/

#table-categorias {

    border-collapse: separate;
    border-spacing: 15px 5px;
}

#btneditcat {
    color: rgba(80, 216, 106, 1);
}

#trashcat {
    color: rgba(237, 34, 67, 1);
}

.input-size {
    width: 50%;
}

.box-size {
    width: 85%;
}

.box-resize {
    width: 95%;
}

.control.has-icons-left .input,
.control.has-icons-left .select select {
    padding-left: 5px;
}

.w-100 {
    width: 100%;
}

.p-t-b {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.tabs.is-boxed li.is-active a {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.tabs.is-fullwidth li {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

aside ul.menu-list.mt-4 ul#options-services li a {
    font-size: 14px;
}

.li-active {
    opacity: 0.5;
}

.inputSearchContainer input {
    padding: 0 10px;
    width: 100%;
}

.icon-search-input {
    position: absolute;
    right: 15px;
    top: 12px;
    color: #b0b0b3;
}

.inputSearchContainer {
    position: relative;
}

@media screen and (max-width: 940px),
print {

    .column.is-full,
    .column.is-full-tablet {
        width: auto;
    }

    .width-box {
        width: 80%;
    }

    .content-box-expand {
        width: 90% !important;
    }

    section#cont-box .item-column.has-text-left.d-grid.newpass input {
        width: 280px;
    }

    section#cont-box .box-change-pass .inputs-chage-pass button {
        width: 280px;
    }
}

.btn-eliminar {
    background: rgba(237, 34, 67, 1);
    color: #FFFFFF;
    width: 148px;
    border-radius: 5px;

}

.btn-eliminar:hover {
    background: rgba(237, 34, 67, 0.7);
    color: #FFFFFF;
}

.btn-cancelar {
    width: 181px;
    border-radius: 5px;
    background: rgba(236, 241, 243, 1);
    color: rgba(76, 76, 102, 1);
    ;
}

.modal-card-title {
    font-weight: 600;
}

table.table.is-striped.table-list tr:hover {
    background: rgba(221, 238, 254, 0.5);
}

.showoptions {
    justify-content: center;
    background: #ffff;
    border-bottom: 1px solid #E5E7EB;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #E5E7EB;
}

.showoptions:hover {
    background: #E5E7EB;
}

#modaleditardatos {
    width: 874px;
}

.editardatos {
    padding: 5rem 5rem !important;
}

.box-editar {
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    padding: 0.5rem;
}

.head-title {
    border-bottom: 1px solid #dbdbdb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #FFFFFF;
    padding: 20px;
}

.pb {
    margin-bottom: 0;
    padding-bottom: 0;
}

.cat-title {
    border-top: 1px solid #dbdbdb;
    width: 98%;
    padding-top: 1rem;
}

.jf-cont {
    justify-content: space-between;
}

.table-title {
    position: relative;
}

.title-h1 {
    font-size: 42px;
}

.title-h2 {
    font-size: 36px;
}

.title-h3 {
    font-size: 32px;
}

.title-h4 {
    font-size: 28px;
}

.title-h5 {
    font-size: 20px;
}

.title-h6 {
    font-size: 18px;
}

.App .closed div#cont-box {
    width: calc(100vw - 100px) !important;
}

#_nuevoFacturador .modal-card,
#_modalContainer .modal-card {
    width: 450px;
}

.main-table tr {
    height: 50px;
}

.notification.is-danger {
    background-color: #f14668;
    color: #fff;
    z-index: 1000;
    top: 10px;
    position: fixed;
    left: calc(50% - 250px);
    width: 500px;
}

h5.subtitle {
    font-weight: 700;
}

.a-initial {
    text-align: initial;
}

.column.a-initial h1 {
    padding-left: 15px;
}

.divisoria {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 25px;
    margin-right: 25px;
}

.mb-auto {
    margin-bottom: auto !important;
}

.welcome-text {
    font-weight: 200;
    font-size: 12px;
}

.d-grid {
    display: grid;
}

.menu ul .d-grid {
    text-align: initial;
    margin-left: 25px;
    margin-right: 25px;
}

.pad-title {
    padding-left: 21px;
}

.t-pad {
    padding: 21px;
    padding-left: 0px;
}

.text-al-ini {
    text-align: initial;
}

.t-al-end {
    text-align: end;
}

.sidebar-menu.footer {
    bottom: 0px;
    clear: both;
    display: block;
    left: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    background-color: #323980;
}

.al {
    text-align: initial !important;
}

tr.tr-size td {
    text-align: initial;
}

div#pendientes {
    text-align: initial;
    font-weight: 400 !important;
}

div#pendientes .columns .column h1 {
    font-weight: 400;
    padding-left: 11px;
}

div#Facturas .columns .column.is-full.d-f .column.is-3 h3.pt-text {
    text-align: initial;
}

.App div#Adjuntas {
    text-align: initial;
}

div#Kyc .columns.mb-4 .column h3.pt-text {
    text-align: initial;
}

aside.menu.sidebar.small ul.menu-list.sidebar-menu.footer div#user_details span {
    display: none;
}

.App section#cont-box label {
    text-align: initial;
}

.item-column.tab {
    text-align: initial;
}

.box-category {
    border-radius: 4px;
    background: #195BAB;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

ion-icon.md.hydrated {
    font-size: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.go2072408551 {
    box-shadow: none !important;
}

button#btnactualizar svg.svg-inline--fa.fa-id-badge.icon-sidebar {
    padding-right: 10px;
}

.button:active,
.button.is-active {
    border: none;
}

.a-center {
    justify-content: center;
    text-align: center;
    align-items: center;
}

.modal-delete {
    margin-bottom: 2rem;
}

.a-center.modal-delete svg.svg-inline--fa.fa-triangle-exclamation {
    font-size: 50px;
    color: rgba(237, 34, 67, 1);
}

#CI_DORS img {
    max-height: 120PX;
}

#CI_FRONT img {
    max-height: 120PX;
}

#CI_DORS {
    cursor: pointer;
}

#CI_FRONT img {
    cursor: pointer;
}

.egreso {
    background: rgba(251, 213, 213, 1);
    color: #9B1C1C;
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
}

span.egreso ion-icon.md.hydrated {
    color: #9B1C1C;
    font-size: 15px;
}

.ingreso {
    background: #DEF7EC;
    border-radius: 10px;
    color: rgba(3, 84, 63, 1);
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

span.ingreso ion-icon.md.hydrated {
    color: rgba(3, 84, 63, 1);
    font-size: 15px;
}

.modal-card.movimientos {
    width: 85% !important;
}

.data-confirm-mail {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/images/background_data_confirm.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    background-attachment: fixed;
}

.data-confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-attachment: fixed;
}

.container.is-fluid.container-fix-login.resetpass {
    background-image: url("../../assets/images/background_data_reset.jpg");
    height: 100vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    background-attachment: fixed;
}

/* .container.is-fluid.container-fix-login.p-3.resetpass {
    background: rgba(50, 57, 128, 1);
} */

.Confirm-data {
    width: 500px;
    justify-content: center;
    text-align: center;
    padding: 5rem;
    border-radius: 20px;
    background-color: #fff;
}

.Confirm-data-pass {
    width: 500px;
    text-align: start;
    padding: 2rem;
    border-radius: 20px;
    background-color: #fff;
}

.Confirm-data-pass h3 {
    justify-content: center;
    text-align: center;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(85, 82, 217, 0.2);
    padding-bottom: 20px;
}

.inpt-pass {
    border-bottom: 1px solid rgba(85, 82, 217, 0.2);
    padding-bottom: 25px;
}

button.button.is-normal.btn-login.pass.mt-5 {
    background: rgba(50, 57, 128, 1);
}

span.icon-mail ion-icon.md.hydrated {
    color: green;
    font-size: 45px;
}

span.icon-mail-error ion-icon.md.hydrated {
    color: red;
    font-size: 45px;
}

p.box-reg.pep {
    width: 100px;
}

p.box-pending.pep {
    width: 100px;
}

.column.address-pa {
    word-wrap: break-word;
}

.column.localte-pa {
    word-wrap: break-word;
}

.modal-card.movimientos {
    min-height: 500px;
}

div#nodatamov {
    border-top: 1px solid #dbdbdb;
    justify-content: center;
    align-items: center;
    text-align: center;
}

div#searchMov div#buscador .column.d-f.align {
    align-items: start !important;
    text-align: start !important;
    justify-content: start !important;
}

span.btnExport {
    color: #323980;
    padding: 10px;
    padding-top: 0px;
    cursor: pointer;
    align-items: center;
    width: auto;
    margin: auto;
}

span.btnExport ion-icon.md.hydrated {
    color: #323980;
}

div#itemExport {
    align-items: self-start;
    text-align: end;
}

.d-flex {
    display: flex;
}

span.btnExport.d-flex p {
    padding-top: 5px;
    padding-left: 5px;
}

span.btnExport.d-flex:hover {
    opacity: 0.5;
}

span.btnExport.d-flex ion-icon.md.hydrated:hover {
    opacity: 0.5;
}

.column.is-full {
    margin-top: 0px;
    padding-top: 0px;
}

div#nicknameversion {
    font-size: 10px;
}

.item-column.dateTime {
    display: grid;
    justify-content: center;
    margin-right: 50px;
}

.errorMsgg {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    background: rgba(249, 249, 250, 1);
    padding: 1rem;
}

.main-inde-c {
    background-image: url('../images/Background-welcome.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

aside.menu.sidebar {
    z-index: 10;
}

.control.has-icons-left.has-icons-right textarea.input.pl-2 {
    min-height: 120px;
}

.container.is-fluid.container-fix-login.p-3.resetpass {
    margin: 0 !important;
    padding: 0 !important;
}

.dropdown-menu {
    display: none;
    left: 40px;
    min-width: 12rem;
    position: absolute;
    top: -14px;
    z-index: 20;
}

.text-change-pass {
    color: #FFCA5A;
}

span.has-text-dark-pass {
    font-size: 12px;
    color: #474747;
}

div#user_details\ d-flex {
    display: flex;
    justify-content: space-around;
}

.dropdown.password-res.is-active .dropdown-trigger button.button {
    background: none;
    border: none !important;
    color: white !important;
}

.dropdown.password-res .dropdown-trigger button.button {
    background: none;
    border: none;
    color: white;
}

div#dropdown-menu .dropdown-content {
    padding: 0;
    border: 1px solid rgba(85, 82, 217, 0.24);
}

aside.menu.sidebar.small div#user_details\ d-flex {
    display: none;
}

.item-column.has-text-left.d-grid.newpass {
    margin-bottom: 20px;
    justify-content: center;
}

input.passrest {
    width: 429px;
}



section#cont-box .item-column.has-text-left.d-grid.newpass button {
    width: 280px;
}

span.togglepassword {
    right: 26px;
    position: relative;
}

.noigual {
    justify-content: center;
    align-items: center !important;
    text-align: center;
    display: grid;
}

.noigual span {
    color: red;
    font-size: 10px;
}

.inputs-chage-pass h1 {
    font-size: 20px;
    margin-bottom: 25px;
}

input#inputPass {
    position: relative;
}

span.togglepss {
    position: absolute;
    top: 227px;
    left: 416px;
}

.box-ci .ml-4.mt-4.d-f.mb-2 embed {
    height: 115px;
}

div#subtitledoc {
    margin-top: 10px;
    cursor: pointer;
}

span#docview {
    text-decoration: underline;
    font-size: 10px;
    cursor: pointer;
}

span#docview:hover {
    opacity: 0.4;
}

.fileselected {
    opacity: 0.7;
    background: rgba(219, 233, 234, 0.6);
    font-size: 10px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

object.viewerDocConstancia {
    height: 449px;
    width: 370px;
}

div#redict {
    margin-top: 2rem;
}

div#resetpasslogin {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.align.mt-4 {
    margin-top: 1rem;
}

div#resetPassLogin {
    padding: 2rem;
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-radius: 10px;
}

span.redirecting {
    font-size: 10px;
}

form#dateEditExpires {
    min-height: 250px;
}

div#declareiva {
    border-top: 0.01rem solid #DDE1E8;
    margin-top: 1rem;
}

label.titleiva {
    text-transform: uppercase;
}

svg.svg-inline--fa.fa-id-badge.icon-sidebar {
    margin-right: 8px;
}

svg.svg-inline--fa.fa-file-lines.icon-sidebar {
    margin-right: 8px;
}

.fileuploader input[type="file"] {
    width: 100%;
}

input[type='file'] {
    color: transparent;
}

.hide {
    display: none;
}

.mb-3.a-end ion-icon {
    color: #485fc7;
    text-align: center;
    font-size: 14px;
}

.mb-3.a-end {
    color: #485fc7;
    background: #F9FAFB;
    padding: 3px;
    border-radius: 5px;
}

.mb-3.a-end :hover {
    color: black;
}

div#takecount {
    justify-content: start;
    text-align: start;
}

section#navvendorsection {
    border-top: 1px solid #E5E7EB;;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 10px;
}

.liside {
    display: inline-block;
    text-align: start;
    text-decoration: none;
    list-style: none;
    padding-left: 10px;
}

.liselected {
    background: #F9FAFB;
    border-radius: 5px;
}

.liside li.is-active a {
    color: #474747;
}
/* 
.liside li.is-active a {
    
    padding: 10px;
    padding-bottom: 5px;
    padding-top: 7px;
    border-radius: 4px;
} */

div#pageselection {
    text-align: end;
}

iframe {
    pointer-events: none !important;
}

.mt-3{
    margin-top: 1rem;
}

div#pageselection {
    align-items: center;
    align-self: center;
}

.liside li.is-active a {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    border-color: #485fc7;
    color: #fff;
    background: rgba(50, 57, 128, 1);
}

.liside li a{
    border: 1px solid #b5b5b596;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    color: #363636;
}

.liside li.notallowed a{
    opacity: 0.4;
    border: 1px solid #b5b5b596;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    color: #363636;
}

.errordataload {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #f3f3f3;
    border-radius: 8px;
}